<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12" lg="12">
        <b-card class="w-100" no-header>
          <template slot="header">
            <span class="mt-2">Question Section</span>
            <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
          </template>

          <b-card no-body>
            <b-card-header>
              Question Section
            </b-card-header>

            <b-card-body>

              <p class="text-muted" v-html="help_text"></p>

            <b-alert class="m-3 mb-0 pb-0" variant="info" :show="model.condition.length > 0">
              <p><b>Shows based on:</b></p>
              <ul>
                <li v-for="(condition, cindex) in model.condition" :key="cindex">{{ condition.description }}</li>
              </ul>
            </b-alert>

              <b-form-group
                label="Label"
                label-for="label-input"
                invalid-feedback="Label is required"
                class="mb-4"
                :state="validateState('label')"
              >
                <b-form-input
                  id="label-input"
                  v-model="model.label"
                  :state="validateState('label')"
                ></b-form-input>
              </b-form-group>

              <InternalData
                v-if="model.internal_data"
                v-bind:internal_data="model.internal_data"
              ></InternalData>

            </b-card-body>
            <b-card-footer class="bg-transparent">
              <b-button
                @click="update"
                variant="outline-success"
                class="float-right"
                >Submit</b-button
              >
            </b-card-footer>
          </b-card>

          <template slot="footer">
            <b-button @click="goBack" class="pull-left">Back</b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "../../../shared/axios/auth";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "QuestionSection",
  mixin: [validationMixin],
  components: {
    InternalData: () => import('../questions/component/InternalData.vue')
  },
  data: () => {
    return {
      model: null,
      help_text: "The pet name can be dynamically inserted into the label/sublabel when <code class='font-weight-bold'>{{PET->NAME}}</code> is added. </br> For example. <br> <code class='font-weight-bold'>Fluffy's date of birth</code> would be <code class='font-weight-bold'>{{PET->NAME}}'s date of birth</code><br>"
    };
  },
  validations() {
    return {
      model: {
        period: {
          required
        }
      }
    };
  },
  methods: {
    validateState(name) {
      if (this.$v.model[name]) {
        const { $dirty, $error } = this.$v.model[name];
        return $dirty ? !$error : null;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    get() {
      let url = "/questions/sections/" + this.$route.params.id + "/";

      axios
        .get(url)
        .then(response => {
          this.model = response.data;
        })
        .catch(error => {
          // Unauthorised
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
          }
        });

      return this.quote;
    },
    update() {
      this.error = false;

      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      } else {
        // Valid

        let url = "/questions/sections/" + this.$route.params.id + "/";

        var post_data = {
          type: this.model.type,
          period: this.model.period
        };

        axios
          .put(url, post_data)
          .then(response => {
            console.log(response.data);
            this.error = false;
            this.$router.push({ name: "QuestionSections" });
          })
          .catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch("logout");
            }
          });
      }
    }
  },
  mounted() {
    this.get();
  }
};
</script>

<style>
#data .card-header {
  padding-top: 1.5rem;
}
</style>
